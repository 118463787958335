import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  MenuItem,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  FormControl,
  InputLabel,
  Select, 
} from '@mui/material';
import { PlayArrow, Pause, Add, Watch, Edit, Delete} from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowDownward, ArrowUpward,  AttachMoney } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import {API_URL, USERS} from "./constants";
import { jwtDecode } from 'jwt-decode';


function  TaskManager({ token, handleLogout }) {
  const [authtoken, setAuthToken] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [expandedTask, setExpandedTask] = useState(null); // Track which task is expanded
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [projects, setProjects] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [assignee, setAssignee] = useState('');
  const [reporter, setReporter] = useState('');
  const [description, setDescription] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectContact, setProjectContact] = useState('');
  const [projectId, ] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [ , setLoading] = useState(false);
  const [error, setError] = useState('');
  const [view, setView] = useState('tasks');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [hourlyRate, setHourlyRate] = useState('');
  const [editedProjects, setEditedProjects] = useState({});
  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null); // Store the task you are editing
  const [projectFilter, setProjectFilter] = useState(''); 
  const [openEditTimeIntervalLogDialog, setOpenEditTimeIntervalLogDialog] = useState(false);
  const [openEditDirectLogDialog, setOpenEditDirectLogDialog] = useState(false);
  const [logToEdit, setLogToEdit] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [openEditCostLogDialog, setOpenEditCostLogDialog] = useState(false);
  const [timeIntervalFilter, setTimeIntervalFilter] = useState('all');  // Track selected time interval
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [customStart, setCustomStart] = useState(null);  // Track custom start date
  const [customEnd, setCustomEnd] = useState(null); 
  const [taskDeadline, setTaskDeadline] = useState(
    new Date(Date.now() + 5 * 24 * 60 * 60 * 1000) // Default to 5 days from now
  );

    const userColors = {
      "Péter": "rgba(138, 43, 226, 0.75)",    // Violet with 50% opacity
      "Pál": "rgba(0, 119, 190, 0.75)",       // Ocean Blue with 50% opacity
      "Vivien": "rgba(255, 215, 0, 0.75)",    // Sun Yellow with 50% opacity
      "Iroda": "rgba(255, 255, 255, 0.75)"    // White with 50% opacity
    };

    // Define available time interval options
    const TIME_INTERVAL_OPTIONS = [
      { label: 'All Time', value: 'all' },
      { label: 'Last Month', value: 'lastMonth' },
      { label: 'Last Week', value: 'lastWeek' },
      { label: 'Custom Range', value: 'custom' },  // Custom Range option
    ];



    // Update filteredTasks whenever filters change
    useEffect(() => {
    // Comprehensive filtering function

    const filterTasks = () => {
      let filtered = tasks;
    
      // Apply time interval filter (if applicable)
      if (timeIntervalFilter !== 'all') {
        let range;
        if (timeIntervalFilter === 'custom') {
          range = { start: customStart, end: customEnd };
        } else {
          range = getTimeRangeForFilter(timeIntervalFilter);
        }
    
        filtered = filtered.filter((task) => {
          const hasLogsInRange =
            task.time_interval_logs.some(
              (log) =>
                isLogWithinTimeRange(log.start_time, range) ||
                (log.end_time && isLogWithinTimeRange(log.end_time, range))
            ) ||
            task.direct_logs.some((log) =>
              isLogWithinTimeRange(log.created_at, range)
            );
    
          const deadlineInRange =
            task.deadline && isLogWithinTimeRange(new Date(task.deadline), range);
    
          const followUpDeadlineInRange =
            task.follow_up_deadline &&
            isLogWithinTimeRange(new Date(task.follow_up_deadline), range);
    
          return hasLogsInRange || deadlineInRange || followUpDeadlineInRange;
        });
      }
    
      // Apply project filter (Client)
      if (projectFilter) {  // Only filter if a specific project (Client) is selected
        filtered = filtered.filter((task) => task.project_id === projectFilter);
      }
    
      // Apply assignee filter
      if (assigneeFilter) {
        filtered = filtered.filter((task) => task.assignee === assigneeFilter);
      }
    
      // Apply status filter
      if (statusFilter) {
        filtered = filtered.filter((task) => task.state === statusFilter);
      }
    
      return filtered;
    };

    let filtered = filterTasks();
    
      // Apply sorting if sortConfig.key is set
      if (sortConfig.key) {
        filtered.sort((a, b) => {
          let aValue = a[sortConfig.key];
          let bValue = b[sortConfig.key];
    
          // Handle null or undefined values
          if (aValue == null && bValue == null) return 0;
          if (aValue == null) return 1;
          if (bValue == null) return -1;
    
          // Check if values are dates
          if (aValue instanceof Date && bValue instanceof Date) {
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
          }
    
          // For string comparison (case-insensitive)
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortConfig.direction === 'asc'
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }
    
          // For numbers and other types
          if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0;
        });
      }
    
      setFilteredTasks(filtered);
    }, [
      tasks,
      timeIntervalFilter,
      customStart,
      customEnd,
      projectFilter,
      assigneeFilter,
      statusFilter,
      sortConfig,
    ]);

    // Function to get the color for an assignee
    const getTaskColor = (assignee) => {
      return userColors[assignee] || "#FFFFFF";  // Default to white if assignee not found
    };

      // Fetch tasks and apply filtering
    useEffect(() => {
      const fetchData = async () => {
        const response = await axios.get(`${API_URL}/tasks/`, { headers: { Authorization: `Bearer ${token}` } });
        setTasks(response.data);
      };
      fetchData();
    }, [token]);

    // Apply filtering based on the selected time interval
    useEffect(() => {
      const filterTasksByTimeInterval = (tasks, timeIntervalFilter, customStart, customEnd) => {
        if (timeIntervalFilter === 'all') {
          return tasks;  // No filtering for "All Time"
        }
      
        let range;
        
        if (timeIntervalFilter === 'custom') {
          // For custom range, use the selected start and end time
          range = { start: customStart, end: customEnd };
        } else {
          // For predefined time intervals (like "Last Month")
          range = getTimeRangeForFilter(timeIntervalFilter);
        }
      
        return tasks.filter(task =>
          task.time_interval_logs.some(log => 
            isLogWithinTimeRange(log.start_time, range) || (log.end_time && isLogWithinTimeRange(log.end_time, range))
          ) ||
          task.direct_logs.some(log => 
            isLogWithinTimeRange(log.created_at, range)
          )
        );
      };
      const filtered = filterTasksByTimeInterval(tasks, timeIntervalFilter, customStart, customEnd);
      setFilteredTasks(filtered);
    }, [tasks, timeIntervalFilter, customStart, customEnd]);

    const handleDeleteProject = async (projectId) => {
      if (window.confirm('Are you sure you want to delete this Client? All the corresponding tasks will be deleted also! This action cannot be undone.')) {
        try {
          await axios.delete(`${API_URL}/projects/${projectId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          alert('Project deleted successfully');
          // Remove the deleted project from the state
          setProjects((prevProjects) => prevProjects.filter((project) => project.id !== projectId));
        } catch (err) {
          console.error('Error deleting project:', err.response ? err.response.data : err.message);
          alert('Error deleting project');
        }
      }
    };
  
    // Export logs to Excel for a single task, including cost logs
    const exportToExcel = (task) => {
      const project = projects.find((proj) => proj.id === task.project_id);
      const hourlyRate = project?.hourly_rate || 0;

      // Create an array for both direct logs, time interval logs, and cost logs for the task
      const exportData = [
        // Time Interval Logs
        ...task.time_interval_logs.map((log) => ({
          taskName: task.name,
          projectName: project?.name || 'Unknown Client',
          logType: 'Time Interval',
          subTask: log.description || 'No description',
          timeSpent: getTimeDifferenceInHours(log.start_time, log.end_time || new Date()), // Time spent in hours
          price: getTimeDifferenceInHours(log.start_time, log.end_time || new Date()) * hourlyRate, // Calculate price
          amountHUF: '', // Empty for non-cost logs
        })),
        // Direct Logs
        ...task.direct_logs.map((log) => ({
          taskName: task.name,
          projectName: project?.name || 'Unknown Client',
          logType: 'Direct Log',
          subTask: log.description,
          timeSpent: log.minutes_logged / 60, // Convert minutes to hours
          price: (log.minutes_logged / 60) * hourlyRate, // Calculate price
          amountHUF: '', // Empty for non-cost logs
        })),
        // Cost Logs
        ...task.cost_logs.map((log) => ({
          taskName: task.name,
          projectName: project?.name || 'Unknown Client',
          logType: 'Cost Log',
          subTask: log.description,
          timeSpent: '', // Empty for cost logs
          price: '', // Empty for cost logs
          amountHUF: log.amount_huf, // Amount in HUF for cost logs
        })),
      ];

      const totalHours = exportData
        .filter((log) => log.timeSpent)
        .reduce((sum, log) => sum + log.timeSpent, 0);
      const totalPrice = exportData
        .filter((log) => log.price)
        .reduce((sum, log) => sum + log.price, 0);
      const totalCost = exportData
        .filter((log) => log.amountHUF)
        .reduce((sum, log) => sum + parseFloat(log.amountHUF), 0);

      // Add total hours, total price, and total cost row at the end
      exportData.push({
        taskName: 'Total',
        projectName: '',
        logType: '',
        subTask: '',
        timeSpent: totalHours,
        price: totalPrice,
        amountHUF: totalCost,
      });

      // Create a worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(exportData, {
        header: ['taskName', 'Client Name', 'logType', 'subTask', 'timeSpent', 'price', 'amountHUF'],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Logs');

      // Download the Excel file
      XLSX.writeFile(workbook, `${task.name}_logs.xlsx`);
    };

    const logCost = async (taskId) => {
      const description = prompt('Enter a description for the cost:');
      const amountHUF = parseFloat(prompt('Enter the cost in HUF:'));
      if (!description || isNaN(amountHUF)) {
        alert('Invalid input. Please provide a description and a valid amount.');
        return;
      }
      try {
        setLoading(true);
        await axios.post(`${API_URL}/tasks/${taskId}/cost`, {
          description,
          amount_huf: amountHUF,
        }, { headers: { Authorization: `Bearer ${token}` } });
        alert('Cost logged successfully');
        setLoading(false);
      } catch (err) {
        setError('Error logging cost');
        setLoading(false);
      }
    };

    const handleEditCostLog = (taskId, log) => {
      if (!log.id) {
        setError('Cost log ID is missing.');
        return;
      }
      setCurrentTaskId(taskId);
      setLogToEdit(log);
      setOpenEditCostLogDialog(true);  // Ensure you have a dialog to edit cost logs
    };



    useEffect(() => {
      const refreshToken = async () => {
        try {
          const response = await axios.post(`${API_URL}/refresh`, {}, {
            withCredentials: true, // Important: send cookies
          });
          const newAccessToken = response.data.access_token;
          setAuthToken(newAccessToken);
          localStorage.setItem('token', newAccessToken);
        } catch (error) {
          console.error("Failed to refresh token:", error);
          handleLogout(); // Log out the user if refresh fails
        }
      };
      // Decode token and refresh if necessary
      const decodeToken = (token) => {
        if (!token) return;
        try {
          const decoded = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decoded.exp < currentTime) {
            refreshToken();  // Automatically refresh the token if it's expired
          }
        } catch (error) {
          console.error("Failed to decode token:", error);
          refreshToken(); // Attempt to refresh if decoding fails
        }
      };
      decodeToken(authtoken);
    }, [authtoken, handleLogout]);



    const handleDeleteCostLog = async (taskId, logId) => {
      if (window.confirm('Are you sure you want to delete this cost log?')) {
        try {
          await axios.delete(`${API_URL}/tasks/${taskId}/cost_logs/${logId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          fetchData();  // Refresh the task data after deletion
        } catch (err) {
          setError('Error deleting cost log');
        }
      }
    };

    const updateCostLog = async () => {
      try {
        await axios.put(
          `${API_URL}/tasks/${currentTaskId}/cost_logs/${logToEdit.id}`,
          {
            description: logToEdit.description,
            amount_huf: parseFloat(logToEdit.amount_huf),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setOpenEditCostLogDialog(false);  // Close the dialog after updating
        setLogToEdit(null);
        setCurrentTaskId(null);
        fetchData();  // Refresh the task data after updating
      } catch (err) {
        setError('Error updating cost log');
      }
    };

    // Time Interval Logs
    const handleEditTimeIntervalLog = (taskId, log) => {
      setCurrentTaskId(taskId);
      setLogToEdit(log);
      setOpenEditTimeIntervalLogDialog(true);
    };
  
    const handleDeleteTimeIntervalLog = async (taskId, logId) => {
      if (window.confirm('Are you sure you want to delete this time interval log?')) {
        try {
          await axios.delete(`${API_URL}/tasks/${taskId}/time_interval_logs/${logId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          fetchData();
        } catch (err) {
          setError('Error deleting time interval log');
        }
      }
    };
  
    // Direct Logs
    const handleEditDirectLog = (taskId, log) => {
      console.log('Editing Direct Log:', log); // Inspect log.id here
      if (!log.id) {
        setError('Direct log ID is missing.');
        return;
      }
      setCurrentTaskId(taskId);
      setLogToEdit(log);
      setOpenEditDirectLogDialog(true);
    };
  
    const handleDeleteDirectLog = async (taskId, logId) => {
      if (window.confirm('Are you sure you want to delete this direct log?')) {
        try {
          await axios.delete(`${API_URL}/tasks/${taskId}/direct_logs/${logId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          fetchData();
        } catch (err) {
          setError('Error deleting direct log');
        }
      }
    };
  
    // Update Functions
  
    const updateTimeIntervalLog = async () => {
      try {
        await axios.put(
          `${API_URL}/tasks/${currentTaskId}/time_interval_logs/${logToEdit.id}`,
          {
            start_time: new Date(logToEdit.start_time),
            end_time: logToEdit.end_time ? new Date(logToEdit.end_time) : null,
            description: logToEdit.description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setOpenEditTimeIntervalLogDialog(false);
        setLogToEdit(null);
        setCurrentTaskId(null);
        fetchData();
      } catch (err) {
        setError('Error updating time interval log');
      }
    };
  
    const updateDirectLog = async () => {
      try {
        await axios.put(
          `${API_URL}/tasks/${currentTaskId}/direct_logs/${logToEdit.id}`,
          {
            description: logToEdit.description,
            minutes_logged: parseFloat(logToEdit.minutes_logged),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setOpenEditDirectLogDialog(false);
        setLogToEdit(null);
        setCurrentTaskId(null);
        fetchData();
      } catch (err) {
        setError('Error updating direct log');
      }
    };


    // Function to sort tasks
    const sortTasks = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
        } else if (sortConfig.key === key && sortConfig.direction === 'desc') {
          direction = 'asc';
        } else {
          direction = 'asc';
        }

        const sortedTasks = [...tasks].sort((a, b) => {
        if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        return 0;
        });

        setTasks(sortedTasks);
        setSortConfig({ key, direction });
    };

  const handleExpandClick = (taskId) => {
    setExpandedTask(expandedTask === taskId ? null : taskId); // Toggle task expansion
  };

  // Calculate the predefined time range based on filter selection
  const getTimeRangeForFilter = (filter) => {
    const now = new Date();
    
    switch (filter) {
      case 'lastMonth':
        return {
          start: new Date(now.getFullYear(), now.getMonth() - 1, 1),  // First day of last month
          end: new Date(now.getFullYear(), now.getMonth(), 0)         // Last day of last month
        };
      case 'lastWeek':
        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() - 7));  // 7 days back
        return { 
          start: startOfWeek,
          end: new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000)  // Add 6 days to get the end of last week
        };
      default:
        return { start: null, end: null };  // For "All Time", no filtering
    }
  };

  // Helper function to check if a log falls within a given time range
  const isLogWithinTimeRange = (logTime, range) => {
    const logDate = new Date(logTime);
    return (!range.start || logDate >= range.start) && (!range.end || logDate <= range.end);
  };


  
  // Fetch tasks and projects from the API
  const fetchData = async () => {
    try {
      setLoading(true);
      const [taskResponse, projectResponse] = await Promise.all([
        axios.get(`${API_URL}/tasks/`, { headers: { Authorization: `Bearer ${token}` } }),
        axios.get(`${API_URL}/projects/`, { headers: { Authorization: `Bearer ${token}` } }),
      ]);
      
      const fetchedProjects = projectResponse.data;
      fetchedProjects.sort((a, b) => a.name.localeCompare(b.name));
      
      const fetchedTasks = taskResponse.data.map(task => ({
        ...task,
        project_name: fetchedProjects.find(proj => proj.id === task.project_id)?.name || 'Unknown',
        cost_logs: task.cost_logs || [],
        direct_logs: task.direct_logs || [],
        time_interval_logs: task.time_interval_logs || [],
        deadline: task.deadline ? new Date(task.deadline) : null,  // Ensure deadline is a Date object
        follow_up_deadline: task.follow_up_deadline ? new Date(task.follow_up_deadline) : null,
      }));
      
      setTasks(fetchedTasks);
      setProjects(fetchedProjects);
      setLoading(false);
    } catch (err) {
      setError('Error fetching data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  // Create a new project
  const createProject = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${API_URL}/projects/`,
        {
          id: null,
          name: projectName,
          contact: projectContact,
          hourly_rate: hourlyRate, // Send the hourly rate
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setProjects((prevProjects) => [
        ...prevProjects,
        { name: projectName, contact: projectContact, hourly_rate: hourlyRate },
      ]);
      setProjectName('');
      setProjectContact('');
      setHourlyRate(''); // Clear hourly rate
      setOpenProjectDialog(false);
      setLoading(false);
    } catch (err) {
      setError('Error creating Client');
      setLoading(false);
    }
  };


  // Open the edit dialog
    const handleEditTask = (task) => {
        setTaskToEdit(task); // Set the task to be edited
        setOpenEditTaskDialog(true);
    };

    // Function to update the task
    const updateTask = async () => {
      try {
        setLoading(true);
    
        // Check the structure of taskToEdit before sending the request
        console.log("Task to be updated:", taskToEdit);
    
        // Make the PUT request with the updated task data
        await axios.put(`${API_URL}/tasks/${taskToEdit.id}`, {
          ...taskToEdit,  // Ensure this object contains all necessary fields
        }, { headers: { Authorization: `Bearer ${token}` } });
    
        setOpenEditTaskDialog(false);
        fetchData(); // Re-fetch the tasks after updating
        setLoading(false);
      } catch (err) {
        // Log the error details to debug the issue
        console.error("Error updating task:", err.response ? err.response.data : err.message);
        setError('Error updating task');
        setLoading(false);
      }
    };
    

  // Create a new task
  const createTask = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/tasks/`, {
        project_id: projectId,
        name: taskName,
        assignee,
        reporter,
        description,
        deadline: taskDeadline || new Date(Date.now() + 5 * 24 * 60 * 60 * 1000),
      }, { headers: { Authorization: `Bearer ${token}` } });
  
      const newTask = response.data;
      setTasks((prevTasks) => [...prevTasks, { ...newTask, time_interval_logs: [], direct_logs: [], cost_logs: [] }]);
      setTaskName('');
      setAssignee('');
      setReporter('');
      setDescription('');
      setOpenTaskDialog(false);
      setLoading(false);
    } catch (err) {
      setError('Error creating task');
      setLoading(false);
    }
  };

  const toggleStatus = async (task, newState) => {
    try {
      setLoading(true);

      let updatedTask = { ...task, state: newState };

      // If changing to 'done' and follow-up deadline is not set
      if (newState === 'done' && !task.follow_up_deadline) {
        updatedTask.follow_up_deadline = new Date(Date.now() + 5 * 24 * 60 * 60 * 1000);
      }
  
      // Optimistically update the task status in local state before the PUT request completes
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t.id === task.id ? { ...t, state: newState } : t // Update the task status in local state
        )
      );
  
      // Make the PUT request to update the task in the backend
      await axios.put(`${API_URL}/tasks/${task.id}`, {
        ...task,
        state: newState // Update task state on the server
      }, { headers: { Authorization: `Bearer ${token}` } });
  
      setLoading(false);
    } catch (err) {
      setError('Error updating task status');
      setLoading(false);
    }
  };
  
  const toggleTaskStatus = async (taskId, notStarted) => {
    try {
      setLoading(true);
  
      let description = '';
  
      if (notStarted) {
        // Starting the task
        description = prompt('Enter a description for this time interval:');
        if (!description) {
          alert('You must provide a description to start the task.');
          setLoading(false);
          return;
        }
      }
  
      // Make the POST request to start or finish the task
      if (notStarted) {
        // Start the task
        await axios.post(
          `${API_URL}/tasks/${taskId}/start`,
          { description },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        // Finish the task
        await axios.post(
          `${API_URL}/tasks/${taskId}/finish`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
  
      // Refresh the task data
      fetchData();
      setLoading(false);
    } catch (err) {
      console.error('Error toggling task status:', err.response ? err.response.data : err.message);
      if (err.response && err.response.status === 401) {
        setError('Unauthorized. Please log in again.');
        handleLogout(); // Optionally log the user out if the token is invalid
      } else {
        setError('Error toggling task status');
      }
      setLoading(false);
    }
  };
  
  
  // Log work on a task
  const logWork = async (taskId) => {
    const description = prompt('Enter work description:');
    const minutesLogged = parseFloat(prompt('Enter worked minutes:'));
    if (!description || isNaN(minutesLogged)) {
      alert('Invalid input. Please provide a description and valid minutes.');
      return;
    }
    try {
      setLoading(true);
      await axios.post(`${API_URL}/tasks/${taskId}/log`, { description, minutes_logged: minutesLogged }, { headers: { Authorization: `Bearer ${token}` } });
      alert('Work logged successfully');
      setLoading(false);
    } catch (err) {
      setError('Error logging work');
      setLoading(false);
    }
  };

  const formatTimeInHoursAndMinutes = (totalHours) => {
    const hours = Math.floor(totalHours); // Get the whole hours
    const minutes = Math.round((totalHours - hours) * 60); // Get the remaining minutes
    return `${hours} hours ${minutes} minutes`;
  };

  // Calculate total hours per project in the last month
  const calculateTotalHoursPerProject = () => {
    const hoursPerProject = projects.map((project) => {
      const projectTasks = tasks.filter((task) => task.project_id === project.id);
      const totalHours = projectTasks.reduce((sum, task) => {
        const logHours = task.direct_logs.reduce((taskSum, log) => taskSum + (log.minutes_logged / 60), 0); // Convert minutes to hours
        const totalTimeSpent = task.time_interval_logs.reduce((total, interval) => {
          return total + getTimeDifferenceInHours(interval.start_time, interval.end_time); // Using hours instead of seconds
        }, 0);
        return sum + logHours + totalTimeSpent;
      }, 0);
  
      // Ensure totalHours is at least 0 (in case there are no tasks or logs)
      return { projectName: project.name, totalHours: totalHours || 0 }; 
    });
  
    return hoursPerProject;
  };

  // Helper function to calculate time difference in hours
  const getTimeDifferenceInHours = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeInSeconds = (endDate - startDate) / 1000;
    return timeInSeconds / 3600; // Convert seconds to hours
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewChange = (newView) => {
    setView(newView);
    setAnchorEl(null);  // Close the menu after selection
    console.log("View changed to:", newView);  // Debugging log to verify the view is being set correctly
  };

  const handleProjectChange = (projectId, field, value) => {
    setEditedProjects((prevState) => ({
      ...prevState,
      [projectId]: {
        ...prevState[projectId],
        [field]: value,
      },
    }));
  };
  
  const handleSaveProject = async (projectId) => {
    // Fetch the original project data as a fallback if `editedProjects[projectId]` is undefined
    const originalProject = projects.find(p => p.id === projectId);
  
    if (!originalProject) {
      alert('Project not found');
      return;
    }
  
    // Merge edited values with the original project values
    const project = {
      name: editedProjects[projectId]?.name || originalProject.name, // Fallback to original name if not edited
      contact: editedProjects[projectId]?.contact || originalProject.contact, // Fallback to original contact
      hourly_rate: editedProjects[projectId]?.hourly_rate || originalProject.hourly_rate, // Fallback to original hourly rate
      description: editedProjects[projectId]?.description || originalProject.description, // Fallback to original description
      state: editedProjects[projectId]?.state || originalProject.state, // Fallback to original state
    };
  
    try {
      await axios.put(`${API_URL}/projects/${projectId}`, project, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Project updated successfully');
      fetchData(); // Re-fetch the data after successful save
    } catch (err) {
      alert('Error updating project');
    }
  };

  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Task and Project Manager
          </Typography>
          <Button color="inherit" onClick={handleMenuClick}>
            Menu
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={() => handleViewChange('tasks')}>List and Add Tasks</MenuItem>
            <MenuItem onClick={() => handleViewChange('projects')}>Reports</MenuItem>
            <MenuItem onClick={() => handleViewChange('editProjects')}>Edit Clients</MenuItem>
          </Menu>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {error && <Typography color="error">{error}</Typography>}

      {view === 'tasks' && (
        // Tasks View
        <Box>
        <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpenTaskDialog(true)}
        style={{ marginTop: '20px' }}
        >
        Add Task
        </Button>

        {/* Add Project Button */}
        <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpenProjectDialog(true)}
        style={{ marginTop: '20px', marginLeft: '20px' }}
        >
        Add Client
        </Button>


        {/* Filter Section */}
        <Typography variant="h6" style={{ marginTop: '20px' }}>Filter Tasks</Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Client</InputLabel>
          <Select
            value={projectFilter}  // This will hold the selected project ID or an empty value for "All Clients"
            onChange={(e) => setProjectFilter(e.target.value)}  // Update the project filter state
          >
            {/* Option for "All Clients" */}
            <MenuItem value="">All Clients</MenuItem>  {/* Empty value represents "All Clients" */}
            
            {/* Loop through your clients and create menu items */}
            {projects
              .slice() // Shallow copy to avoid mutation
              .sort((a, b) => a.name.localeCompare(b.name)) // Sort by client name
              .map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
        <InputLabel>Assignee</InputLabel>
        <Select value={assigneeFilter} onChange={(e) => setAssigneeFilter(e.target.value)}>
            <MenuItem value="">All</MenuItem>
            {USERS.map((assignee) => (
            <MenuItem key={assignee} value={assignee}>
                {assignee}
            </MenuItem>
            ))}
        </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <MenuItem value="">All</MenuItem>
            {['todo', 'in progress', 'done'].map((status) => (
            <MenuItem key={status} value={status}>
                {status}
            </MenuItem>
            ))}
        </Select>
        </FormControl>

        {/* Time Interval Filter */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Time Interval</InputLabel>
          <Select
            value={timeIntervalFilter}
            onChange={(e) => setTimeIntervalFilter(e.target.value)}
          >
            {TIME_INTERVAL_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Only show custom date-time pickers if "Custom Range" is selected */}
        {timeIntervalFilter === 'custom' && (
          <>
            <TextField
              label="Start Time"
              type="datetime-local"
              value={
                customStart
                  ? customStart.toISOString().slice(0, -1)
                  : ''
              }
              onChange={(e) => setCustomStart(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Time"
              type="datetime-local"
              value={
                customEnd
                  ? customEnd.toISOString().slice(0, -1)
                  : ''
              }
              onChange={(e) => setCustomEnd(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </>
        )}

        {/* Display Tasks */}
        <Typography variant="h6" style={{ marginTop: '20px' }}>Tasks</Typography>
                  <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>
                    Client
                    <IconButton onClick={() => sortTasks('project_name')}>
                      {sortConfig.key === 'project_name' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Task Name
                    <IconButton onClick={() => sortTasks('name')}>
                      {sortConfig.key === 'name' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Task <br/> Deadline
                    <IconButton onClick={() => sortTasks('deadline')}>
                      {sortConfig.key === 'deadline' ? (
                        sortConfig.direction === 'asc' ? <ArrowUpward /> : <ArrowDownward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Follow-Up <br/> Deadline
                    <IconButton onClick={() => sortTasks('follow_up_deadline')}>
                      {sortConfig.key === 'follow_up_deadline' ? (
                        sortConfig.direction === 'asc' ? <ArrowUpward /> : <ArrowDownward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Assignee
                    <IconButton onClick={() => sortTasks('assignee')}>
                      {sortConfig.key === 'assignee' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Reporter
                    <IconButton onClick={() => sortTasks('reporter')}>
                      {sortConfig.key === 'reporter' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Status <br/>
                    <IconButton onClick={() => sortTasks('state')}>
                      {sortConfig.key === 'state' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
              {filteredTasks.map((task) => (
                    <React.Fragment key={task.id}>
                      <TableRow style={{ backgroundColor: getTaskColor(task.assignee) }}>
                        <TableCell>{task.project_name}</TableCell>
                        <TableCell>{task.name}</TableCell>
                        <TableCell>
                          {task.deadline ? new Date(task.deadline).toLocaleString() : 'Not Set'}
                        </TableCell>
                        <TableCell>
                          {task.follow_up_deadline ? new Date(task.follow_up_deadline).toLocaleString() : 'Not Set'}
                        </TableCell>
                        <TableCell>{task.assignee}</TableCell>
                        <TableCell>{task.reporter}</TableCell>
                        <TableCell>
                          <FormControl>
                            <Select
                              value={task.state}
                              onChange={(e) => toggleStatus(task, e.target.value)}
                              displayEmpty
                            >
                              <MenuItem value="todo">To-do</MenuItem>
                              <MenuItem value="in progress">In Progress</MenuItem>
                              <MenuItem value="done">Done</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              toggleTaskStatus(
                                task.id,
                                task.time_interval_logs.length === 0 ||
                                  task.time_interval_logs[task.time_interval_logs.length - 1].end_time != null
                              )
                            }
                            color="primary"
                          >
                            {task.time_interval_logs.length === 0 ||
                            task.time_interval_logs[task.time_interval_logs.length - 1].end_time != null ? (
                              <PlayArrow />
                            ) : (
                              <Pause />
                            )}
                          </IconButton>
                          <IconButton onClick={() => logWork(task.id)} color="primary">
                            <Watch />
                          </IconButton>
                          <IconButton onClick={() => logCost(task.id)} color="primary">
                              <AttachMoney />
                          </IconButton>
                          <IconButton onClick={() => handleEditTask(task)} color="primary">
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleExpandClick(task.id)}>
                            {expandedTask === task.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                          <Collapse in={expandedTask === task.id} timeout="auto" unmountOnExit>
                            <Box margin={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography variant="subtitle1">Time Interval Logs</Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => exportToExcel(task)} 
                              >
                                Export Logs to Excel
                              </Button>
                            </Box>
                              {task.time_interval_logs.length === 0 ? (
                                <Typography>No time interval logs</Typography>
                              ) : (
                                <ul>
                                  {task.time_interval_logs.map((log, index) => (
                                    <li key={log.id || index}>
                                      <Typography>
                                        <strong>Start:</strong> {new Date(log.start_time).toLocaleString()},
                                        <strong> End:</strong> {log.end_time ? new Date(log.end_time).toLocaleString() : 'In Progress'}
                                      </Typography>
                                      {log.description && (
                                        <Typography>
                                          <strong>Description:</strong> {log.description}
                                        </Typography>
                                      )}
                                      <Typography variant="caption" color="textSecondary">
                                        Logged by {log.created_by} on {new Date(log.created_at).toLocaleString()}
                                      </Typography>
                                      {/* Edit and Delete Buttons */}
                                      <IconButton onClick={() => handleEditTimeIntervalLog(task.id, log)} color="primary">
                                        <Edit />
                                      </IconButton>
                                      <IconButton onClick={() => handleDeleteTimeIntervalLog(task.id, log.id)} color="secondary">
                                        <Delete />
                                      </IconButton>
                                    </li>
                                  ))}
                                </ul>
                              )}

                              {/* Direct Logs */}
                              <Typography variant="subtitle1" style={{ marginTop: '20px' }}>Direct Logs</Typography>
                              {task.direct_logs.length === 0 ? (
                                <Typography>No direct logs</Typography>
                              ) : (
                                <ul>
                                  {task.direct_logs.map((log, index) => (
                                    <li key={log.id || index}>
                                      <Typography>
                                        <strong>Description:</strong> {log.description}, 
                                        <strong> Minutes:</strong> {log.minutes_logged}
                                      </Typography>
                                      <Typography variant="caption" color="textSecondary">
                                        Logged by {log.created_by} on {new Date(log.created_at).toLocaleString()}
                                      </Typography>
                                      {/* Edit and Delete Buttons */}
                                      <IconButton onClick={() => handleEditDirectLog(task.id, log)} color="primary">
                                        <Edit />
                                      </IconButton>
                                      <IconButton onClick={() => handleDeleteDirectLog(task.id, log.id)} color="secondary">
                                        <Delete />
                                      </IconButton>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              {/* Cost Logs */}
                              <Typography variant="subtitle1" style={{ marginTop: '20px' }}>Cost Logs</Typography>
                              {task.cost_logs.length === 0 ? (
                                <Typography>No cost logs</Typography>
                              ) : (
                                <ul>
                                  {/* Cost Logs */}
                                  {task.cost_logs.map((log, index) => (
                                    <li key={log.id || index}>
                                      <Typography>
                                        <strong>Description:</strong> {log.description}, 
                                        <strong> Amount (HUF):</strong> {log.amount_huf}
                                      </Typography>
                                      <Typography variant="caption" color="textSecondary">
                                        Logged by {log.created_by} on {new Date(log.created_at).toLocaleString()}
                                      </Typography>
                                      {/* Edit and Delete Buttons */}
                                      <IconButton onClick={() => handleEditCostLog(task.id, log)} color="primary">
                                        <Edit />
                                      </IconButton>
                                      <IconButton onClick={() => handleDeleteCostLog(task.id, log.id)} color="secondary">
                                        <Delete />
                                      </IconButton>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </Box>

                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

        {/* Dialog for Adding Task */}
        <Dialog open={openTaskDialog} onClose={() => setOpenTaskDialog(false)}>
          <DialogTitle>Add Task</DialogTitle>
          <DialogContent>
            <DialogContentText>Fill in the task details below.</DialogContentText>
            <FormControl fullWidth margin="normal">
              <InputLabel>Client</InputLabel>
              <Select
                value={projectFilter}  // This will hold the selected project ID or an empty value for "All Clients"
                onChange={(e) => setProjectFilter(e.target.value)}  // Update the project filter state
              >
                {/* Option for "All Clients" */}
                <MenuItem value="">All Clients</MenuItem>  {/* Empty value represents "All Clients" */}
                
                {/* Loop through your clients and create menu items */}
                {projects
                  .slice() // Shallow copy to avoid mutation
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort by client name
                  .map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              label="Task Name"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Assignee</InputLabel>
              <Select value={assignee} onChange={(e) => setAssignee(e.target.value)}>
                {USERS.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Reporter</InputLabel>
              <Select value={reporter} onChange={(e) => setReporter(e.target.value)}>
                {USERS.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={3}
            />
            {/* Task Deadline Field */}
            <TextField
              label="Task Deadline"
              type="datetime-local"
              value={
                taskDeadline
                  ? taskDeadline.toISOString().slice(0, -1)
                  : ''
              }
              onChange={(e) => setTaskDeadline(new Date(e.target.value))}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenTaskDialog(false)} color="secondary">Cancel</Button>
            <Button onClick={createTask} color="primary">Create Task</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for Adding Project */}
        <Dialog open={openProjectDialog} onClose={() => setOpenProjectDialog(false)}>
        <DialogTitle>Add Client</DialogTitle>
        <DialogContent>
            <DialogContentText>Fill in the client details below.</DialogContentText>
            <TextField
            label="Client Name"
            value={projectName || ''}
            onChange={(e) => setProjectName(e.target.value)}
            fullWidth
            margin="normal"
            />
            <TextField
            label="Client Contact"
            value={projectContact || ''}
            onChange={(e) => setProjectContact(e.target.value)}
            fullWidth
            margin="normal"
            />
            <TextField
            label="Hourly Rate (HUF)"
            value={hourlyRate || ''}
            onChange={(e) => setHourlyRate(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenProjectDialog(false)} color="secondary">Cancel</Button>
            <Button onClick={createProject} color="primary">Add Client</Button>
        </DialogActions>
        </Dialog>

        <Dialog open={openEditTaskDialog} onClose={() => setOpenEditTaskDialog(false)}>
          <DialogTitle>Edit Task</DialogTitle>
          <DialogContent>
            <DialogContentText>Modify the task details below.</DialogContentText>
            
            {/* Client Field */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Client</InputLabel>
              <Select
                value={taskToEdit?.project_id || ''}
                onChange={(e) => setTaskToEdit({ ...taskToEdit, project_id: e.target.value })}
              >
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Task Deadline"
              type="datetime-local"
              value={
                taskToEdit?.deadline
                  ? new Date(taskToEdit.deadline).toISOString().slice(0, -1)
                  : ''
              }
              onChange={(e) => setTaskToEdit({ ...taskToEdit, deadline: new Date(e.target.value) })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="Follow-Up Deadline"
              type="datetime-local"
              value={
                taskToEdit?.follow_up_deadline
                  ? new Date(taskToEdit.follow_up_deadline).toISOString().slice(0, -1)
                  : ''
              }
              onChange={(e) => setTaskToEdit({ ...taskToEdit, follow_up_deadline: new Date(e.target.value) })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            
            {/* Task Name Field */}
            <TextField
              label="Task Name"
              value={taskToEdit?.name || ''}
              onChange={(e) => setTaskToEdit({ ...taskToEdit, name: e.target.value })}
              fullWidth
              margin="normal"
            />
            
            {/* Description Field */}
            <TextField
              label="Description"
              value={taskToEdit?.description || ''}
              onChange={(e) => setTaskToEdit({ ...taskToEdit, description: e.target.value })}
              fullWidth
              margin="normal"
              multiline
              rows={3}
            />
            
            {/* Assignee Field */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Assignee</InputLabel>
              <Select
                value={taskToEdit?.assignee || ''}
                onChange={(e) => setTaskToEdit({ ...taskToEdit, assignee: e.target.value })}
              >
                {USERS.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            {/* Reporter Field */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Reporter</InputLabel>
              <Select
                value={taskToEdit?.reporter || ''}
                onChange={(e) => setTaskToEdit({ ...taskToEdit, reporter: e.target.value })}
              >
                {USERS.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditTaskDialog(false)} color="secondary">Cancel</Button>
            <Button onClick={updateTask} color="primary">Save Changes</Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for Editing Time Interval Log */}
        <Dialog
            open={openEditTimeIntervalLogDialog}
            onClose={() => setOpenEditTimeIntervalLogDialog(false)}
          >
            <DialogTitle>Edit Time Interval Log</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Modify the time interval log details below.
              </DialogContentText>
              <TextField
                label="Start Time"
                type="datetime-local"
                value={
                  logToEdit && logToEdit.start_time
                    ? new Date(logToEdit.start_time).toISOString().slice(0, -1)
                    : ''
                }
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, start_time: e.target.value })
                }
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Time"
                type="datetime-local"
                value={
                  logToEdit && logToEdit.end_time
                    ? new Date(logToEdit.end_time).toISOString().slice(0, -1)
                    : ''
                }
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, end_time: e.target.value })
                }
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Description"
                value={logToEdit ? logToEdit.description : ''}
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, description: e.target.value })
                }
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditTimeIntervalLogDialog(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={updateTimeIntervalLog} color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for Editing Direct Log */}
          <Dialog
            open={openEditDirectLogDialog}
            onClose={() => setOpenEditDirectLogDialog(false)}
          >
            <DialogTitle>Edit Direct Log</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Modify the direct log details below.
              </DialogContentText>
              <TextField
                label="Description"
                value={logToEdit ? logToEdit.description : ''}
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, description: e.target.value })
                }
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <TextField
                label="Minutes Logged"
                type="number"
                value={logToEdit ? logToEdit.minutes_logged : ''}
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, minutes_logged: e.target.value })
                }
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditDirectLogDialog(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={updateDirectLog} color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>
          
          {/* Dialog for Editing Cost Log */}

          <Dialog
            open={openEditCostLogDialog}  // Open the dialog when needed
            onClose={() => setOpenEditCostLogDialog(false)}
          >
            <DialogTitle>Edit Cost Log</DialogTitle>
            <DialogContent>
              <TextField
                label="Description"
                value={logToEdit ? logToEdit.description : ''}
                onChange={(e) => setLogToEdit({ ...logToEdit, description: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Amount (HUF)"
                type="number"
                value={logToEdit ? logToEdit.amount_huf : ''}
                onChange={(e) => setLogToEdit({ ...logToEdit, amount_huf: e.target.value })}
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditCostLogDialog(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={updateCostLog} color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>

        </Box>
       )}
      {view === 'projects' && (
          <Box>
            {/* Display total hours per project */}
            <Typography variant="h6" style={{ marginTop: '20px' }}>Total minutes per Client (Last Month)</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Total Time Spent</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculateTotalHoursPerProject().map((project) => (
                    <TableRow key={project.projectName}>
                      <TableCell>{project.projectName}</TableCell>
                      <TableCell>
                        {typeof project.totalHours === 'number'
                          ? formatTimeInHoursAndMinutes(project.totalHours)
                          : '0 hours 0 minutes'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
      )}

      {/* Edit projects view */}
      {view === 'editProjects' ? (
        <Box>
            <Typography variant="h5" gutterBottom>
            Edit Clients
            </Typography>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Client Name</TableCell>
                    <TableCell>Hourly Rate (HUF)</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projects.map((project) => (
                    <TableRow key={project.id}>
                      <TableCell>
                        <TextField
                          value={editedProjects[project.id]?.name || project.name}
                          onChange={(e) => handleProjectChange(project.id, 'name', e.target.value)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={editedProjects[project.id]?.hourly_rate || project.hourly_rate}
                          onChange={(e) => handleProjectChange(project.id, 'hourly_rate', e.target.value)}
                          type="number"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={editedProjects[project.id]?.contact || project.contact}
                          onChange={(e) => handleProjectChange(project.id, 'contact', e.target.value)}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel>Status</InputLabel>
                          <Select
                            value={editedProjects[project.id]?.state || project.state}
                            onChange={(e) => handleProjectChange(project.id, 'state', e.target.value)}
                          >
                            <MenuItem value="in progress">In Progress</MenuItem>
                            <MenuItem value="done">Done</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={editedProjects[project.id]?.description || project.description}
                          onChange={(e) => handleProjectChange(project.id, 'description', e.target.value)}
                          fullWidth
                          multiline
                          rows={3}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSaveProject(project.id)}
                        >
                          Save
                        </Button>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDeleteProject(project.id)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        </Box>
        ) : null}
    </Container>
  );
}

export default TaskManager;
