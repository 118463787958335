import React, { useState } from 'react';
import Login from './Login';
import TaskManager from './TaskManager';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  // This handles logging out and removing the token
  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
  };

  if (!token) {
    return <Login setToken={setToken} />;
  }

  return (
    <div>
      <TaskManager token={token} handleLogout={handleLogout} />
    </div>
  );
}

export default App;
